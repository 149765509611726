import {Route, Routes, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {PayContext} from "../context/PayContext";
import PayContextState from "../interface/PayContextState";
import RouteList from "./../../../routes/Routes";
import {get} from "lodash";
import PayController from "../controller/PayController";
import {Toaster} from "react-hot-toast";
import BackToPage from "../../Home/view/BackToPage/BackToPage";

function Main() {
    const location = useLocation();
    const [count, setCount] = useState(0);
    const [state, setState] = useState<PayContextState>({
        categoryActive: 'all',
        isAccess: false,
        focused: false
    });

    useEffect(() => count === 0 ? (new PayController(count, setCount)).init() : undefined, [count])

    useEffect(() => count !== 0 ? (new PayController(count, setCount)).start() : undefined, [location.pathname])

    useEffect(() => {
        const handleVisibilityChange = () => {
            setState({...state, focused: !state.focused});
        };

        const handleWindowBlur = () => {
            setState({...state, focused: false});
        };

        window.addEventListener("focus", handleVisibilityChange);
        window.addEventListener("blur", handleWindowBlur);

        return () => {
            window.removeEventListener("focus", handleVisibilityChange);
            window.removeEventListener("blur", handleWindowBlur);
        };
    }, [state]);

    return (
        <PayContext.Provider value={{state, setState}}>
            {!state.focused ? <BackToPage state={state} setState={setState}/> : null}
            <Routes>
                {
                    RouteList().map(
                        (item, index) =>
                            <Route key={`route-${index}-${item.path}`} path={item.path} element={item.element}
                                   index={get(item, "index", false)}/>
                    )
                }
            </Routes>
            <Toaster position="top-center" reverseOrder={false}/>
        </PayContext.Provider>
    )
}

export default Main;