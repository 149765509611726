import {get, keys} from "lodash";
import PayController from "../../features/Main/controller/PayController";

export default class EventListenerRepository {
    list: {}

    constructor() {
        this.list = {
            "payInit": PayController.payInit.bind(this)
        }
    }

    register() {
        keys(this.list).map(key => {
            return window.addEventListener(key, get(this.list, key), false)
        })
    }

    uninstall() {
        keys(this.list).map(key => {
            return window.removeEventListener(key, get(this.list, key), false)
        })
    }
}