import PayRepository from "../../../Main/repository/PayRepository";
import PayController from "../../../Main/controller/PayController";
import Input from "../../../../components/Form/Input/Input";

export default function SettingsForm() {
    const settingPay = PayRepository.getSettingPay();
    const feilds = [
        {
            className: "skip-amount-in-barcode",
            label: "مقدار اسکیپ قیمت از چپ در بارکد",
            type: "number",
            defaultValue: settingPay.skipAmountInBarcode,
            onChange: (e: any) => PayController.setSkipAmountBarcode(e.target.value),
        },
        {
            className: "netbee-public-key",
            label: "کلید عمومی نت بی پوز",
            type: "text",
            defaultValue: settingPay.netbeePublicKey,
            onChange: (e: any) => PayController.setNetbeePublicKey(e.target.value)
        },
        {
            className: "public-key",
            label: "کلید عمومی",
            type: "text",
            disabled: true,
            defaultValue: settingPay.publicKey,
            onChange: (e: any) => PayController.setPublicKey(e.target.value)
        },
        {
            className: "callBack",
            label: "API بازگشت پس از پرداخت",
            type: "text",
            defaultValue: settingPay.callBackUrl,
            onChange: (e: any) => PayController.setApi(e.target.value)
        },
        {
            className: "method-call-back",
            label: "متد API",
            type: "text",
            defaultValue: settingPay.methodCallBack,
            onChange: (e: any) => PayController.setApiMethod(e.target.value)
        },
    ]

    return (
        <div className="settings-form-container">
            <h3>تنظیمات کلی</h3>
            <form onSubmit={e => e.preventDefault()}>
                {feilds.map((item, i) => (
                    <Input item={item} key={item.className + i}/>
                ))}
            </form>
        </div>
    );
}