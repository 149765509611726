import React, {useEffect} from "react";
import {Button, Modal} from "react-bootstrap";
import StateModalModel from "./Model/StateModalModel";
import "./assets/modal.scss";
import {get} from "lodash";

export default function ModalComponents({state, setState}: {
    state: StateModalModel,
    setState: React.Dispatch<React.SetStateAction<StateModalModel>>
}) {

    const handleClose = () => setState({...state, show: false});

    useEffect(() => {
        window.addEventListener('closeModal', handleClose)

        return () => window.removeEventListener('closeModal', handleClose)
    }, [])

    return (
        <>
            <Modal show={state.show} onExit={state.onExit} centered={true} onHide={handleClose} dir="rtl"
                   className={`modal-components ${state.modalClassName}`}>
                {get(state, "title", "").length ? (
                    <Modal.Header closeButton><Modal.Title>{state.title}</Modal.Title></Modal.Header>) : null}
                <Modal.Body>{state.body}</Modal.Body>
                {
                    state.closeLabel.length || state.submitLabel.length ?
                        (
                            <Modal.Footer>
                                {state.closeLabel.length ?
                                    <Button variant="back"
                                            className={get(state, "closeClassName", "")}
                                            onClick={handleClose}>{state.closeLabel}</Button> : null}
                                {state.submitLabel.length ?
                                    <Button variant="theme-secondary"
                                            className={get(state, "submitClassName", "")}
                                            onClick={state.submit}>{state.submitLabel}</Button> : null}
                            </Modal.Footer>
                        )
                        :
                        null
                }
            </Modal>
        </>
    );
}