import PaySetting from "../interface/PaySettingLocalStorage";
import LocalStorageRepository from "../../../repository/LocalStorageRepository";
import {set} from "lodash";
import PaySettingLocalStorage from "../interface/PaySettingLocalStorage";

export default class PayRepository {

    static getSettingPay(): PaySettingLocalStorage {
        try {
            const defaultValue = JSON.stringify(PayRepository.DefaultPaySetting())
            const localStorage = LocalStorageRepository.get("setting", defaultValue)
            return JSON.parse(localStorage as string)
        } catch (e) {
            return this.DefaultPaySetting();
        }
    }

    static setSettingPay(key: string, value: any) {
        const data = PayRepository.getSettingPay()
        set(data, key, value)
        LocalStorageRepository.set("setting", data)
    }

    private static DefaultPaySetting(): PaySetting {
        return {
            skipAmountInBarcode: 0,
            netbeePublicKey: 'MFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAEjWrmg+dm7+Kk0cX3PJcP7OMv8KalJfvcWvrf6O2wXaNaD0BK3htCMszWhy3uAc9Wyudtq49sM9P7oPELaDhJwA==',
            publicKey: '',
            privateKey: '',
            callBackUrl: '',
            password: '',
            methodCallBack: 'get',
        }
    }
}