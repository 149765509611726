import {ReactComponent as BackIcon} from "../../../assets/image/arrow-left.svg";
import React, {useState} from "react";
import ModalComponents from "../../../components/Modal/Modal";
import StateModalModel from "../../../components/Modal/Model/StateModalModel";
import {NavLink} from "react-router-dom";

export default function HomeHeader() {
    const [stateModal, setStateModal] = useState<StateModalModel>({
        body: <>asfasf</>,
        closeLabel: "",
        show: false,
        submit(): void {
        },
        submitLabel: "",
        title: "",
        modalClassName: "password-modal-component",
    })

    return (
        <section className={"setting-header"}>
            <div className={"header-container"}>
                <div className="back">
                    <NavLink to={'/'}>
                        <BackIcon/>
                    </NavLink>
                </div>
                <div className={"store-name"}>
                    <h1>تنظیمات</h1>
                </div>
            </div>
            <ModalComponents state={stateModal} setState={setStateModal}/>
        </section>

    )
}