import toast from "react-hot-toast";

export default class Toast {
    static success(message: string) {

        return toast.success(message)
    }

    static error(message: string) {

        return toast.error(message)
    }

    static loading(message: string) {
        return toast.loading(message)
    }
}