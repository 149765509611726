import './assets/css/input.scss'
import {ReactComponent as EyeIcon} from "../../../assets/image/eye.svg";
import {ReactComponent as EyeSlashIcon} from "../../../assets/image/eye-slash.svg";
import {useState} from "react";

export default function Input({item}: { item: any }) {
    const [isShowPass, setIsShowPass] = useState(false)

    const ShowIconEye4Password = () => {
        return item.type === 'password' ?
            isShowPass ? <EyeSlashIcon onClick={() => setIsShowPass(false)}/> :
                <EyeIcon onClick={() => setIsShowPass(true)}/>
            : null
    }

    return (
        <div className={`${item.className ? item.className : ''} custom-input`}>
            <label htmlFor={item.className}>{item.label}</label>
            <div className={'input'}>
                <input type={item.type === 'password' ? isShowPass ? 'text' : "password" : item.type}
                       id={item.className}
                       placeholder={item.placeholder}
                       disabled={item.disabled}
                       defaultValue={item.defaultValue}
                       onChange={item.onChange}/>
                <div className={'icons'}>
                    <ShowIconEye4Password/>
                </div>
            </div>
        </div>
    );
}