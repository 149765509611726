import { useState, useEffect } from "react";

const useCountDown = (initialValue: number): number => {
    const [count, setCount] = useState<number>(initialValue);

    useEffect(() => {
        if (count <= 0) return;

        const timer = setInterval(() => {
            setCount((prev) => prev - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, [count]);

    return count;
};

export default useCountDown;
