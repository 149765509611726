import payContext from "../../Main/interface/PayContext";
import {NavigateFunction} from "react-router-dom";

export default class SettingController {
    static backToHome(context: payContext, navigate: NavigateFunction) {
        context.setState((prevState) => ({...prevState, isAccess: false}));

        requestAnimationFrame(() => {
            navigate(`/`, {replace: true});
        });
        return;
    }
}