import React, {useState} from "react";
import './assets/css/ChangePasswordModal.scss'
import {ChangePasswordModalState} from "./interfaces/ChangePasswordModalState";
import ChangePasswordModalController from "./controller/ChangePasswordModalController";
import Input from "../../../../components/Form/Input/Input";
import {ReactComponent as BackIcon} from "../../../../assets/image/arrow-left.svg";

export default function ChangePasswordModal() {
    const [state, setState] = useState<ChangePasswordModalState>({
        prevPassword: "",
        newPassword: "",
        confirmNewPassword: "",
    })
    const passwordFields = [
        {
            type: "password",
            onChange: (e: any) => setState({...state, prevPassword: e.target.value}),
            label: "گذرواژه فعلی",
            id: 'old-password',
            placeholder: "گذرواژه فعلی",
        },
        {
            type: "password",
            onChange: (e: any) => setState({...state, newPassword: e.target.value}),
            label: "گذرواژه جدید",
            id: 'news-password',
            placeholder: "گذرواژه جدید",
        },
        {
            type: "password",
            onChange: (e: any) => setState({...state, confirmNewPassword: e.target.value}),
            label: "تکرار گذرواژه جدید",
            id: 'repeat-news-password',
            placeholder: "تکرار گذرواژه جدید",
        },
    ]

    return (
        <div className={'change-password-modal'}>
            <BackIcon className={'back-icon'} onClick={() => window.dispatchEvent(new Event('closeModal'))}/>
            <h2>تغییر گذرواژه</h2>
            <p>برای تغییر گذرواژه لطفا موارد زیر را تکمیل کنید.</p>
            <form onSubmit={e => new ChangePasswordModalController(state, setState).submitForm(e)}>
                {passwordFields.map(field => (
                    <Input key={field.id} item={field}/>
                ))}
                {state.error ?
                    <div className="error-change-password-modal">
                        <p>{state.message}</p>
                    </div>
                    : null}
                <div className="footer-change-password-modal">
                    <button type="submit">تغییر گذرواژه</button>
                </div>
            </form>
        </div>
    )
}