import {random, set, toNumber} from "lodash";
import PayRepository from "../repository/PayRepository";
import React from "react";
import EventController from "../../../controller/EventController";
import EventListenerRepository from "../../../repository/System/EventListenerRepository";
import LocalStorageRepository from "../../../repository/LocalStorageRepository";
import ECKeyPairGenerator from "./ECKeyPairGenerator";

export default class PayController {
    protected state: number
    protected setState: React.Dispatch<React.SetStateAction<number>>

    constructor(state: number, setState: React.Dispatch<React.SetStateAction<number>>) {
        this.state = state;
        this.setState = setState;
    }

    init() {
        // Register Re Render
        this.registerRerender();

        this.reRender();
        this.start();

        return this.uninstallRerender();
    }

    static async payInit() {
        const settingPay = PayRepository.getSettingPay();

        // set password
        if (!settingPay.password) {
            const hashPassword = await PayController.passwordHash("master")
            const password = settingPay.password ? settingPay.password : hashPassword
            LocalStorageRepository.set("setting", set(settingPay, "password", password))
        }

        // set public key and private key
        const keyGenerator = new ECKeyPairGenerator();

        if (!settingPay.privateKey)
            setTimeout(async () => {
                try {
                    LocalStorageRepository.set("setting", {
                        ...settingPay,
                        publicKey: await keyGenerator.getPublicKey() ? await keyGenerator.getPublicKey() : "",
                        privateKey: await keyGenerator.getPrivateKey() ? await keyGenerator.getPrivateKey() : "",
                    })
                } catch (error) {
                    console.error("Error retrieving keys:", error);
                }
            }, 1000);

        LocalStorageRepository.set("setting", settingPay)
    }

    static setSkipAmountBarcode(number: number) {
        return PayRepository.setSettingPay("skipAmountInBarcode", toNumber(number));
    }

    static setNetbeePublicKey(key: string) {
        return PayRepository.setSettingPay("netbeePublicKey", key);
    }

    static setPublicKey(key: string) {
        return PayRepository.setSettingPay("publicKey", key);
    }

    static setApi(api: string) {
        return PayRepository.setSettingPay("callBackUrl", api);
    }

    static setApiMethod(method: 'get' | 'post' | 'put' | 'delete') {
        return PayRepository.setSettingPay("methodCallBack", method);
    }

    static async checkPassword(password: string) {
        const setting = PayRepository.getSettingPay();
        const hashPassword = await PayController.passwordHash(password)
        return setting.password === hashPassword;
    }

    static async passwordHash(password: string) {
        const encoder = new TextEncoder();
        const data = encoder.encode(password);
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    }

    static async changePassword(prevPassword: string, newPassword: string) {
        const setting = PayRepository.getSettingPay();
        const hashPassword = await PayController.passwordHash(prevPassword)

        if (setting.password !== hashPassword) {
            return false
        }

        const hashNewPassword = await PayController.passwordHash(newPassword)
        PayRepository.setSettingPay("password", hashNewPassword)
        return true
    }

    static async resetPassword() {
        const hashPassword = await PayController.passwordHash("master")
        PayRepository.setSettingPay("password", hashPassword)
    }

    start() {
        const dispatches = ["payInit"]

        dispatches.map(k => EventController.dispatch(k))
    }

    reRender() {
        this.setState(random(999999999))
    }

    registerRerender() {
        // Register Events
        (new EventListenerRepository()).register();

        window.addEventListener('reRender', this.reRender.bind(this), false)
    }

    uninstallRerender() {
        // Register Events
        (new EventListenerRepository()).uninstall();

        window.removeEventListener('reRender', this.reRender.bind(this), false)
    }
}
