import {useNavigate} from "react-router-dom";
import React, {useContext, useState} from "react";
import "./assets/passwordModal.scss"
import CheckPassModalState from "./interfaces/CheckPassModalState";
import CheckPassModalController from "./controller/CheckPassModalController";
import {PayContext} from "../../../Main/context/PayContext";
import Input from "../../../../components/Form/Input/Input";
import {ReactComponent as BackIcon} from "../../../../assets/image/arrow-left.svg";

export default function CheckPassModal() {
    const navigate = useNavigate();
    const context = useContext(PayContext)
    const [state, setState] = useState<CheckPassModalState>({
        value: "",
    })

    return (
        <div className={"check-pass-modal"}>
            <BackIcon className={'back-icon'} onClick={() => window.dispatchEvent(new Event('closeModal'))}/>
            <div className={'check-pass-modal-title'}>
                <h2>تنظیمات</h2>
                <p>برای دسترسی به تنظیمات پرداخت، گذرواژه را وارد کنید.</p>
            </div>
            <div>
                <div className={'parent-password'}>
                    <Input item={{
                        label: "گزرواژه",
                        type: "password",
                        onChange: (e: any) => setState({value: e.target.value}),
                    }}/>
                    {state.error ? <small>{state.message}</small> : null}
                </div>
                <button type={'button'} className={"submit-btn"}
                        onClick={() => new CheckPassModalController(state, setState).submitPassword(context, navigate)}>تایید
                </button>
            </div>
        </div>
    )
}