import React from "react";
import {NavigateFunction} from "react-router-dom";
import CheckPassModalState from "../interfaces/CheckPassModalState";
import payContext from "../../../../Main/interface/PayContext";
import PayController from "../../../../Main/controller/PayController";

export default class CheckPassModalController {
    protected state: CheckPassModalState;
    protected setState: React.Dispatch<React.SetStateAction<CheckPassModalState>>;

    constructor(state: CheckPassModalState, setState: React.Dispatch<React.SetStateAction<CheckPassModalState>>) {
        this.state = state;
        this.setState = setState;
    }

    async submitPassword(context: payContext, navigate: NavigateFunction) {
        if (await PayController.checkPassword(this.state.value)) {
            context.setState((prevState) => ({...prevState, isAccess: true}));

            requestAnimationFrame(() => {
                navigate(`/setting`, {replace: true});
            });
            return;
        }

        return this.updateState({error: true, message: "گذرواژه معتبر نمی‌باشد."});
    }


    private updateState(state: object) {
        const newState = {...this.state, ...state};
        this.state = newState;
        this.setState(newState)
    }
}
