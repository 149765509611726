import React, {useState} from "react";
import StateModalModel from "../../../../components/Modal/Model/StateModalModel";
import ChangePasswordModal from "../ChangePasswordModal/ChangePasswordModal";
import ModalComponents from "../../../../components/Modal/Modal";

export default function OtherSettingForm() {
    const [stateModal, setStateModal] = useState<StateModalModel>({
        body: <ChangePasswordModal/>,
        closeLabel: "",
        show: false,
        submit(): void {
        },
        submitLabel: "",
        title: "",
        modalClassName: "password-modal-component",
    })

    return (
        <div className="other-setting-form-container">
            <h3>تنظیمات امنیتی</h3>

            <div className="change-password">
                <p>تغییر گذرواژه</p>
                <button onClick={() => setStateModal({...stateModal, show: true})}>تغییر</button>
            </div>

            <ModalComponents state={stateModal} setState={setStateModal}/>
        </div>
    );
}