import React from "react";
import Ws from "../../../controller/Ws";
import SignatureManager from "../../Main/controller/SignatureManager";
import PayRepository from "../../Main/repository/PayRepository";
import generateUUID from "../../../utility/generateUUID";
import {get, toNumber} from "lodash";
import {NavigateFunction} from "react-router-dom";

export default class HomeController {
    protected state: any;
    protected setState: React.Dispatch<React.SetStateAction<any>>;

    constructor(state: any, setState: React.Dispatch<React.SetStateAction<any>>) {
        this.state = state;
        this.setState = setState;
    }

    async sendCodeToNetBeePos(navigate: NavigateFunction) {
        if (!this.state.barcode.length || this.state.loading) return null;

        this.setState({...this.state, loading: true});
        const uuid = generateUUID();
        const wsInstance = new Ws("ws://localhost:2449");

        try {
            await wsInstance.connect();
            wsInstance.send(await this.createRequestPay(HomeController.skipNumber(this.state.barcode), uuid));

            wsInstance.onMessage = async (message: string) => this.validationResponse(message, navigate).then(() => {
                wsInstance.close()
            });
        } catch (error) {
            navigate(`/error`, {replace: true});
            console.error("Failed to connect to WebSocket:", error);
        }
    }

    private async createRequestPay(amount: number, uuid: string, payload?: string) {
        const settingPay = PayRepository.getSettingPay();
        return JSON.stringify({
            "type": "payment_request",
            "data": {
                "entity_type": "payment_request",
                "amount": toNumber(`${amount}0`),
                "stan_id": uuid,
                "payload": payload,
                "sign": await SignatureManager.sign(settingPay.privateKey, `#${amount},${uuid},${payload ? payload : ''}#`)
            }
        })
    }

    private async validationResponse(message: string, navigate: NavigateFunction) {
        const msg = JSON.parse(message);
        const settingPay = PayRepository.getSettingPay();

        const publicKeyPem = settingPay.netbeePublicKey;
        const signature = get(msg, "data.sign", "").trim();
        const data = `#${get(msg, "data.error", "")},${get(msg, "data.stan_id", "")},#`.trim();
        const verify = await SignatureManager.verify(publicKeyPem, signature, data)

        if (verify) {
            if (msg.type === "payment_failed") navigate(`/error`, {replace: true});
            if (msg.type === "payment_success") navigate(`/success`, {replace: true});
        } else {
            if (msg.type === "payment_failed") navigate(`/error`, {replace: true});
        }
    }

    static skipNumber(str: string): number {
        const settingPay = PayRepository.getSettingPay();
        const slicedString = str.slice(0, settingPay.skipAmountInBarcode);
        const numericPart = slicedString.match(/\d+/);
        return numericPart ? parseInt(numericPart[0]) : 0;
    }
}
