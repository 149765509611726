import {createContext} from "react";
import PayContextState from "../interface/PayContext";

export const PayContext = createContext<PayContextState>({
    state: {
        categoryActive: 'all',
        isAccess: false,
        focused: true
    },
    setState: () => {
    }
})