import React from "react";
import {ChangePasswordModalState} from "../interfaces/ChangePasswordModalState";
import Toast from "../../../../../utility/toast";
import PayController from "../../../../Main/controller/PayController";
import PayRepository from "../../../../Main/repository/PayRepository";

export default class ChangePasswordModalController {
    protected state: ChangePasswordModalState;
    protected setState: React.Dispatch<React.SetStateAction<ChangePasswordModalState>>;

    constructor(state: ChangePasswordModalState, setState: React.Dispatch<React.SetStateAction<ChangePasswordModalState>>) {
        this.state = state;
        this.setState = setState;
    }

    async submitForm(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()

        const {prevPassword, newPassword, confirmNewPassword} = this.state;

        this.updateState({error: false})

        if (!this.initialValidation(prevPassword, newPassword, confirmNewPassword)) return

        if (!await this.validationPassword(newPassword, confirmNewPassword)) return

        Toast.success("گذرواژه تغییر یافت.")
        window.dispatchEvent(new Event('closeModal'))
        return await PayController.changePassword(prevPassword, newPassword)
    }

    async validationPassword(newPassword: string, confirmNewPassword: string) {
        const setting = PayRepository.getSettingPay();
        const hashPassword = await PayController.passwordHash(this.state.prevPassword)

        // check prev password
        if (setting.password !== hashPassword) {
            this.updateState({
                error: true,
                message: "گذرواژه فعلی معتر نمیباشد."
            });
            return false
        }

        // check new password
        if (newPassword !== confirmNewPassword) {
            this.updateState({
                error: true,
                message: "تکرار گذرواژه جدید معتبر نمیباشد."
            });
            return false
        }
        return true
    }

    initialValidation(prevPassword: string, newPassword: string, confirmNewPassword: string) {
        if (!prevPassword) {
            this.updateState({error: true, message: "وارد کردن گذرواژه فعلی اجباری میباشد."});
            return false
        }
        if (!newPassword) {
            this.updateState({error: true, message: "وارد کردن گذرواژه جدید اجباری میباشد"});
            return false
        }
        if (!confirmNewPassword) {
            this.updateState({error: true, message: "وارد کردن تکرار گذرواژه جدید اجباری میباشد"});
            return false;
        }
        return true;
    }

    private updateState(state: object) {
        const newState = {...this.state, ...state};
        this.state = newState;
        this.setState(newState)
    }
}
