export default class ECKeyPairGenerator {
    private privateKey: CryptoKey | null = null;
    private publicKey: CryptoKey | null = null;

    constructor() {
        this.generateKeys();
    }

    private async generateKeys() {
        try {
            const keyPair = await window.crypto.subtle.generateKey(
                {
                    name: "ECDSA",
                    namedCurve: "P-256",
                },
                true,
                ["sign", "verify"]
            );

            this.privateKey = keyPair.privateKey;
            this.publicKey = keyPair.publicKey;
        } catch (error) {
            console.error("Error generating key pair:", error);
        }
    }

    public async getPrivateKey(): Promise<string> {
        if (!this.privateKey) throw new Error("Private key not generated yet");

        const exportedKey = await window.crypto.subtle.exportKey("pkcs8", this.privateKey);
        return this.arrayBufferToBase64(exportedKey);
    }

    public async getPublicKey(): Promise<string> {
        if (!this.publicKey) throw new Error("Public key not generated yet");

        const exportedKey = await window.crypto.subtle.exportKey("spki", this.publicKey);
        return this.arrayBufferToBase64(exportedKey);
    }

    private arrayBufferToBase64(buffer: ArrayBuffer): string {
        const binary = Array.from(new Uint8Array(buffer))
            .map((byte) => String.fromCharCode(byte))
            .join('');
        return btoa(binary);
    }
}