export default class SignatureManager {
    static async sign(privateKeyPem: any, data: any) {
        const key = await crypto.subtle.importKey(
            "pkcs8",
            SignatureManager.pemToArrayBuffer(privateKeyPem),
            {
                name: "ECDSA",
                namedCurve: "P-256"
            },
            false,
            ["sign"]
        );

        // Generate the raw signature
        const rawSignature = await crypto.subtle.sign(
            {
                name: "ECDSA",
                hash: {name: "SHA-256"}
            },
            key,
            new TextEncoder().encode(data)
        );

        // Convert raw signature to DER format
        const derSignature = SignatureManager.convertRawSignatureToDER(new Uint8Array(rawSignature));

        // Return base64-encoded DER signature
        return btoa(String.fromCharCode(...derSignature as any));
    }

    static async verify(publicKeyPem: any, base64DerSignature: any, data: any) {
        // Import the public key
        const key = await crypto.subtle.importKey(
            "spki",
            SignatureManager.pemToArrayBuffer(publicKeyPem),
            {
                name: "ECDSA",
                namedCurve: "P-256"
            },
            false,
            ["verify"]
        );

        // Decode the base64-encoded DER signature
        const derSignature = new Uint8Array(
            atob(base64DerSignature)
                .split("")
                .map(c => c.charCodeAt(0))
        );

        // Convert DER signature to raw signature
        const rawSignature = SignatureManager.convertDERToRawSignature(derSignature);

        // Verify the signature
        const isValid = await crypto.subtle.verify(
            {
                name: "ECDSA",
                hash: {name: "SHA-256"}
            },
            key,
            rawSignature,
            new TextEncoder().encode(data)
        );

        return isValid;
    }

    static convertDERToRawSignature(derSignature: any) {
        if (derSignature[0] !== 0x30) {
            throw new Error("Invalid DER signature: does not start with SEQUENCE marker (0x30)");
        }

        const sequenceLength = derSignature[1];
        if (sequenceLength !== derSignature.length - 2) {
            throw new Error("Invalid DER signature: Sequence length mismatch");
        }

        let offset = 2; // Skip SEQUENCE marker and length byte

        // Parse R
        if (derSignature[offset] !== 0x02) {
            throw new Error("Invalid DER signature: missing INTEGER marker for R");
        }

        const rLength = derSignature[offset + 1];
        const rStart = offset + 2;
        const rEnd = rStart + rLength;

        if (rEnd > derSignature.length) {
            throw new Error(`Invalid DER signature: R length out of bounds (Declared: ${rLength}, Remaining: ${derSignature.length - rStart})`);
        }
        if (rLength > 32) {
            console.warn("Warning: R length exceeds 32 bytes; truncating to fit.");
        }

        const r = derSignature.slice(rStart, rEnd);
        offset = rEnd;

        // Parse S
        if (derSignature[offset] !== 0x02) {
            throw new Error("Invalid DER signature: missing INTEGER marker for S");
        }

        const sLength = derSignature[offset + 1];
        const sStart = offset + 2;
        const sEnd = sStart + sLength;

        if (sEnd > derSignature.length) {
            throw new Error(`Invalid DER signature: S length out of bounds (Declared: ${sLength}, Remaining: ${derSignature.length - sStart})`);
        }
        if (sLength > 32) {
            console.warn("Warning: S length exceeds 32 bytes; truncating to fit.");
        }

        const s = derSignature.slice(sStart, sEnd);

        // Ensure R and S are 32 bytes each
        const rPadded = new Uint8Array(32);
        const sPadded = new Uint8Array(32);

        if (r.length <= 32) {
            rPadded.set(r, 32 - r.length);
        } else {
            console.warn("R is longer than 32 bytes; truncating.");
            rPadded.set(r.slice(-32)); // Take the last 32 bytes
        }

        if (s.length <= 32) {
            sPadded.set(s, 32 - s.length);
        } else {
            console.warn("S is longer than 32 bytes; truncating.");
            sPadded.set(s.slice(-32)); // Take the last 32 bytes
        }

        return new Uint8Array([...rPadded as any, ...sPadded as any]);
    }

    static convertRawSignatureToDER(rawSignature: any) {
        const r = rawSignature.slice(0, rawSignature.length / 2);
        const s = rawSignature.slice(rawSignature.length / 2);

        function integerToDER(intArray: any) {
            let i = 0;
            while (i < intArray.length && intArray[i] === 0) i++;
            if (i === intArray.length) return Uint8Array.of(0);
            if (intArray[i] > 127) return Uint8Array.of(0, ...intArray.slice(i));
            return intArray.slice(i);
        }

        const rDer = integerToDER(r);
        const sDer = integerToDER(s);

        const length = rDer.length + sDer.length + 4;
        return Uint8Array.of(
            0x30,
            length,
            0x02,
            rDer.length,
            ...rDer,
            0x02,
            sDer.length,
            ...sDer
        );
    }

    static pemToArrayBuffer(pem: any) {
        const b64 = pem.replace(/-----BEGIN [^-]+-----|-----END [^-]+-----|\n/g, "");
        const binary = atob(b64);
        const arrayBuffer = new Uint8Array(binary.length);
        for (let i = 0; i < binary.length; i++) {
            arrayBuffer[i] = binary.charCodeAt(i);
        }
        return arrayBuffer.buffer;
    }
}