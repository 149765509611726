import './assets/css/setting.scss'
import HomeHeader from "./veiw/HomeHeader";
import SettingsForm from "./veiw/SettingsForm/SettingsForm";
import OtherSettingForm from "./veiw/OtherSettingForm/OtherSettingForm";
import SettingController from "./controller/SettingController";
import {NavLink, useNavigate} from "react-router-dom";
import React, {useContext} from "react";
import {PayContext} from "../Main/context/PayContext";

export default function Setting() {
    const navigate = useNavigate();
    const context = useContext(PayContext)

    return (
        <div className="setting-container">
            <HomeHeader/>
            <div className="setting-box">
                <SettingsForm/>
                <OtherSettingForm/>

                <div className="footer">
                    <button type="submit" onClick={() => SettingController.backToHome(context, navigate)}>تایید</button>
                    <NavLink to={'/'}>بازگشت</NavLink>
                </div>
            </div>
        </div>
    );
}