import Success from "../features/success/Success";
import Error from "../features/error/Error";
import Setting from "../features/Setting/Setting";
import Home from "../features/Home/Home";
import Inaccessibility from "../features/inaccessibility/Inaccessibility";
import {has} from "lodash";

function Routes() {
    const isKiosk = has(window, "Kiosk")

    if (!isKiosk)
        return [
            {path: "/", element: <Inaccessibility/>, title: "عدم دسترسی", index: true},
            {path: "*", element: <Inaccessibility/>, title: "عدم دسترسی", index: true},
        ]

    return [
        {path: "/", element: <Home/>, title: "خانه", index: true},
        {path: "/success", element: <Success/>, title: "پرداخت موفق", index: true},
        {path: "/error", element: <Error/>, title: "پرداخت ناموفق", index: true},
        {path: "/setting", element: <Setting/>, title: "تنظیمات", index: true},
    ]
}

export default Routes
