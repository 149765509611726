import './assets/css/Home.scss'
import {useEffect, useState} from "react";
import {ReactComponent as SettingIcon} from "./assets/image/setting-bold.svg";
import HomeController from "./controller/HomeController";
import StateModalModel from "../../components/Modal/Model/StateModalModel";
import ModalComponents from "../../components/Modal/Modal";
import CheckPassModal from "./view/CheckPassModal/CheckPassModal";
import Lottie from "react-lottie";
import BgAnimation from "./assets/image/bg.json";
import {Spinner} from 'react-bootstrap';
import {useNavigate, useSearchParams} from "react-router-dom";
import PayController from "../Main/controller/PayController";

export default function Home() {
    const [searchParams] = useSearchParams();
    const resetPassword = searchParams.get("reset-password");
    const navigate = useNavigate();
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: BgAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const [stateModal, setStateModal] = useState<StateModalModel>({
        body: <CheckPassModal/>,
        closeLabel: "",
        show: false,
        submit(): void {
        },
        submitLabel: "",
        title: "",
        modalClassName: "password-modal-component",
    })

    const [state, setState] = useState({
        barcode: '',
        loading: false,
        focused: false,
    })

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key !== "Enter") {
                setState((prev) => ({
                    ...prev,
                    barcode: prev.barcode + event.key,
                }));
            } else {
                new HomeController(state, setState).sendCodeToNetBeePos(navigate).then();
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [state.loading, state.barcode]);

    useEffect(() => {
        if (resetPassword)
            PayController.resetPassword().then()
    }, []);

    return (
        <div className={`home-container ${state.focused ? 'focused' : "no-focused"}`}>
            <button className={'setting-icon'} onClick={() => setStateModal({...stateModal, show: true})}>
                <SettingIcon/>
            </button>
            <div className="home-content">
                <div className="thumb">
                    <Lottie
                        options={defaultOptions}
                        isClickToPauseDisabled
                        height={'60%'}
                        width={'60%'}
                    />
                    {state.loading ? <div className="loading-container">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        <span>در انتظار پرداخت</span>
                    </div> : null}
                </div>
                <h1>پرداخت از طریق بارکد</h1>
                <p>جهت پرداخت لطفا رسید خود را بر روی بارکدخوان قرار دهید</p>
            </div>
            <span className="badge">NetBee.online</span>
            <ModalComponents state={stateModal} setState={setStateModal}/>
        </div>
    );
}