export default function BackToPage({state, setState}: { state: any, setState: any }) {

    return (
        <div className="back-to-page-container" onClick={() => setState({...state, focused: true})}>
            <h1>برای فعال سازی اپلیکیشن اینجا لمس کنید</h1>
            <button onClick={() => {
                setState({...state, focused: true})
            }}>فعالسازی
            </button>
        </div>
    );
}